<template>
  <div v-hotkey="keymap" class="kt-grid kt-grid--hor kt-grid--root">
    <KTHeaderMobile></KTHeaderMobile>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page"
    >
      <KTAside v-if="asideEnabled"></KTAside>
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
        id="kt_wrapper"
      >
        <KTHeader></KTHeader>
        <div
          class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
          id="kt_content"
        >
          
          <div
            class="kt-container kt-grid__item kt-grid__item--fluid"
            v-bind:class="{ 'kt-container--fluid': contentFluid }"
          >
            <transition name="fade-in-up">
              <router-view></router-view>
            </transition>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <!--<KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>-->
    <!-- <KTQuickPanel></KTQuickPanel> -->
    <KTScrollTop></KTScrollTop>
    <google-spin v-show="$store.state.passport.loadingStack"></google-spin>
    <dialog-timezone></dialog-timezone>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import KTAside from "@/views/theme/aside/Aside.vue";
import KTAside from "@/views/theme/aside/Aside2.vue";
//import KTHeader from "@/views/theme/header/Header.vue";
import KTHeader from "@/views/theme/header/Header2.vue";
import KTHeaderMobile from "@/views/theme/header/HeaderMobile.vue";
import KTFooter from "@/views/theme/footer/Footer.vue";
import HtmlClass from "@/common/htmlclass.service";

//import KTStickyToolbar from "@/views/partials/layout/StickyToolbar.vue";
// import KTQuickPanel from "@/views/partials/layout/QuickPanel.vue";
import KTScrollTop from "@/views/partials/layout/ScrollTop";
import Loader from "@/views/partials/content/Loader.vue";
import {Google} from "vue-loading-spinner";
import DialogTimezone from '@/views/oms/Configuraciones/DialogTimezone.vue';
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/store/htmlclass.module.js";

export default {
  name: "Base",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    //KTStickyToolbar,
    // KTQuickPanel,
    KTScrollTop,
    Loader,
    "google-spin": Google,
    DialogTimezone
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");

    // initialize html element classes
    HtmlClass.init();

    var vm = this;
    this.axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if(response.data){
        if(response.data.message){
          vm.$bvToast.toast(response.data.message, {
            title: `Información`,
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-center'
          });
        }
      }
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      
      if(error.response){
        if(error.response.data){
          switch(typeof error.response.data){
            case "string":
              vm.$bvToast.toast(error.response.data, {
                title: `Error`,
                variant: 'danger',
                solid: true,
                toaster: 'b-toaster-bottom-center'
              });
              break;
            case "object":
                if(error.response.data.message){
                  vm.$bvToast.toast(error.response.data.message, {
                    title: `Error`,
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                  });
                }
                if( error.response.data.errors ){
                  Object.keys(error.response.data.errors).forEach( llave => {
                    vm.$bvToast.toast(error.response.data.errors[llave][0], {
                      title: `Error`,
                      variant: 'danger',
                      solid: true,
                      toaster: 'b-toaster-bottom-center'
                    });
                    
                  });
                }
                break;
          }
        }
      }
      return Promise.reject(error);
    });
  },
  mounted() {
    // check if current user is authenticated
    /*
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
    */

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
    }, 2000);
    
  },
  methods: {
    handleOpenDialogTimezone(){
      this.$emit('openDialogTimezone');
    },
    handleGotoArhive(){
      this.$router.push({ name: "ordenes archivadas" });
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      //"breadcrumbs",
      //"pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page laoder is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !!this.layoutConfig("loader.enabled");
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    keymap () {
      return {
        // 'esc+ctrl' is OK.
        'ctrl+enter': this.handleOpenDialogTimezone,
        'ctrl+s+t': this.handleGotoArhive
      }
    }
  }
};
</script>
<style lang="scss">
.spinner.spinner--google{
    z-index: 9999;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
}
</style>
